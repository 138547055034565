import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Form } from "../../../../form/Form";
import store from "../../../../store";
import { Alert, Box, Button, LinearProgress, Snackbar } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormFieldError,
  setTempFormUpdateData,
} from "../../../../form/formReducer";
import moment from "moment";
import { setOpenSanckBar } from "./calendarReducer";

const CreateEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const googeConnectTime = useSelector(
    (state) => state.loginUserKey.userInfo.google_calendar_connect_time
  );
  const connectedGmail = useSelector(
    (state) => state.loginUserKey.userInfo.connected_gmail
  );
  const createEventFormData = useSelector(
    (state) => state.formSlice.formData.createEventForm
  );
  const createEventFormSubmissionState = useSelector(
    (state) => state.formSlice.formSubmissionState.createEventForm
  );
  const cancelButtonClicked = useSelector((state) =>
    Boolean(state.formSlice.customButtonClicked?.createEventForm?.goBack001)
  );
  const formCurrData = useSelector((state) =>
    state?.formSlice?.formData ? state.formSlice.formData : {}
  );

  ///////////////////////////// Custom Validation ////////////////////////
  // const isValidLastname = (data) => data === "Das";

  const [item, setItem] = useState(store.getState().loginUserKey.userInfo);
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const [loaderFlag, setLoaderFlag] = useState(false);
  const [editData, setEditData] = useState(null);

  const formData = {
    id: "createEventForm",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "api/add-or-update-event-data",
    endPointBodyWrapper: "data",
    urlPathOnSuccessfulFormSubmission: "/calendar-management-event-listing",
    formButtonClass: "submitbtnsection",
    fields: [
      //////////////////////////////////// Text Input Event Title ///////////////////////////////
      {
        id: 0,
        label: "Event Title",
        name: "event_title",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          maxLength: "MaxLength length 20 allowed",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.event_title
            : "",
      },

      //////////////////////////////////// Text Area Input Event Details//////////////////////////
      // {
      //   id: 1,
      //   label: "Event Details",
      //   name: "description",
      //   className: "formGroup eventDetails countDiv__column--col6",
      //   type: "textarea",
      //   rows: 4,
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     minLength: "Minimum length 20 required",
      //   },
      //   defaultValue: editData && Object.keys(editData).length > 0 ? editData.description : ''
      // },
      {
        id: 1,
        label: "Product",
        name: "product_id",
        className: "formGroup product countDiv__column--col6",
        type: "select",
        values: [
          {
            val: "PECE platform and TM-Flow",
            name: "PECE platform and TM-Flow",
          },
          {
            val: "BioEnergetics platform with neoGEN Series",
            name: "BioEnergetics platform with neoGEN Series",
          },
          {
            val: "Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs",
            name: "Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs",
          },
          {
            val: "Meeting wih Beto Paredes from Aspire Partners",
            name: "Meeting wih Beto Paredes from Aspire Partners",
          },
          {
            val: "Meeting wih Beto Paredes from Decision Doc",
            name: "Meeting wih Beto Paredes from Decision Doc",
          },
          {
            val: "Meeting with Beto Paredes to review Aspire Pharmacy Business relationships",
            name: "Meeting with Beto Paredes to review Aspire Pharmacy Business relationships",
          },
          {
            val: "Meeting with Beto Paredes to review Bluecoast Pharmacy Business relationships",
            name: "Meeting with Beto Paredes to review Bluecoast Pharmacy Business relationships",
          },
          {
            val: "Meeting with Beto Paredes to review Aspire Underpayment Lead opportunities",
            name: "Meeting with Beto Paredes to review Aspire Underpayment Lead opportunities",
          },
          {
            val: "Meeting with Beto Paredes to review Bluecoast Underpayment Lead opportunities",
            name: "Meeting with Beto Paredes to review Bluecoast Underpayment Lead opportunities",
          },
          {
            val: "Review of the Access Health Underpayments program with Beto Paredes",
            name: "Review of the Access Health Underpayments program with Beto Paredes",
          },
          {
            val: "Review of the Access Health Free Pharmacy program for larger organizations",
            name: "Review of the Access Health Free Pharmacy program for larger organizations",
          },
          {
            val: "Underpayment Discovery Meeting with Lucas",
            name: "Underpayment Discovery Meeting with Lucas",
          },

          {
            val: "Meeting with Practice2lab",
            name: "Meeting with Practice2lab",
          },

          {
            val: "Meeting with Clinicalspeed",
            name: "Meeting with Clinicalspeed",
          },
          {
            val: "Meeting with Underpayments",
            name: "Meeting with Underpayments",
          },
          {
            val: "Meeting with Divine Admin",
            name: "Meeting with Divine Admin",
          },
          {
            val: "Meeting wih Jeannette Skinner from Aspire Partners",
            name: "Meeting wih Jeannette Skinner from Aspire Partners",
          },
          {
            val: "Meeting wih Beto Paredes",
            name: "Meeting wih Beto Paredes",
          },
        ],
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultView: "Product",
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.product_id[0]
            : "",
      },

      //////////////////////////////// Select Event Type/////////////////////////////////////
      {
        id: 2,
        label: "Event Type",
        name: "event_type",
        className: "formGroup eventType countDiv__column--col6",
        type: "select",
        values: [
          {
            val: 1,
            name: "Discovery Call with Beto Paredes for BioEnergetics",
          },
          { val: 2, name: "PECE portal discovery webcast with Michael White" },
          { val: 3, name: "BioEnergetics Integration Meeting" },
          { val: 4, name: "PECE™ Integration Meeting" },
          {
            val: 5,
            name: "Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs",
          },
          { val: 6, name: "Meeting wih Beto Paredes from Aspire Partners" },
          { val: 7, name: "Meeting wih Beto Paredes from Decision Doc" },
          {
            val: 8,
            name: "Meeting with Beto Paredes to review Aspire Pharmacy Business relationships",
          },
          {
            val: 9,
            name: "Meeting with Beto Paredes to review Bluecoast Pharmacy Business relationships",
          },
          {
            val: 10,
            name: "Meeting with Beto Paredes to review Aspire Underpayment Lead opportunities",
          },
          {
            val: 11,
            name: "Meeting with Beto Paredes to review Bluecoast Underpayment Lead opportunities",
          },
          {
            val: 12,
            name: "Review of the Access Health Underpayments program with Beto Paredes",
          },
          {
            val: 13,
            name: "Review of the Access Health Free Pharmacy program for larger organizations",
          },
          { val: 14, name: "Underpayment Discovery Meeting with Lucas" },
          { val: 15, name: "Practice2Lab Meeting with Lucas" },
          { val: 16, name: "Meeting with Practice2lab" },
          { val: 17, name: "Meeting with Clinicalspeed" },
          { val: 18, name: "Meeting with Underpayments" },
          { val: 19, name: "Meeting with Divine Admin" },
          {
            val: 20,
            name: "Meeting wih Jeannette Skinner from Aspire Partners",
          },
          {
            val: 21,
            name: "Meeting wih Beto Paredes",
          },
        ],
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultView: "Event Type",
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.event_type
            : "",
        sx: { m: 1, minWidth: 120 },
      },

      /////////////////////////////// Date Picker Start Date////////////////////////////////
      {
        id: 3,
        label: "Start Date",
        name: "start_date",
        className: "formGroup datePicker countDiv__column--col3",
        type: "datePicker",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
        // defaultValue: new Date().getTime(),
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.start_date_unix
            : null,
      },

      /////////////////////////////// Date Picker End Date////////////////////////////////
      {
        id: 4,
        label: "End Date",
        name: "end_date",
        className: "formGroup datePicker countDiv__column--col3",
        type: "datePicker",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
        // defaultValue: new Date().getTime(),
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.end_date_unix
            : null,
      },

      //////////////////////////////// Single Check box Day ////////////////////////////
      {
        id: 17,
        type: "html",
        className: "formGroup heading singleCheckbox countDiv__column--col12",
        value: "Available Week Days",
      },

      {
        id: 5,
        label: "Sunday",
        name: "sun",
        className: "formGroup singleCheckbox",
        hint: "Sunday",
        type: "singleCheckbox",
        values: { key: 0, val: "Sunday" },
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.sun : false,
      },
      {
        id: 6,
        label: "Monday",
        name: "mon",
        className: "formGroup singleCheckbox",
        hint: "Monday",
        type: "singleCheckbox",
        values: { key: 1, val: "Monday" },
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.mon : false,
      },
      {
        id: 7,
        label: "Tuesday",
        name: "tues",
        className: "formGroup singleCheckbox",
        hint: "Tuesday",
        type: "singleCheckbox",
        values: { key: 2, val: "Tuesday" },
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.tues : false,
      },
      {
        id: 8,
        label: "Wednesday",
        name: "wed",
        className: "formGroup singleCheckbox",
        hint: "Wednesday",
        type: "singleCheckbox",
        values: { key: 3, val: "Wednesday" },
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.wed : false,
      },
      {
        id: 9,
        label: "Thursday",
        name: "thurs",
        className: "formGroup singleCheckbox",
        hint: "Thursday",
        type: "singleCheckbox",
        values: { key: 4, val: "Thursday" },
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.thurs : false,
      },
      {
        id: 10,
        label: "Friday",
        name: "fri",
        className: "formGroup singleCheckbox",
        hint: "Friday",
        type: "singleCheckbox",
        values: { key: 5, val: "Friday" },
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.fri : false,
      },
      {
        id: 11,
        label: "Saturday",
        name: "sat",
        className: "formGroup singleCheckbox",
        hint: "Saturday",
        type: "singleCheckbox",
        values: { key: 6, val: "Saturday" },
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.sat : false,
      },

      //////////////////////////////// Select Time Span/////////////////////////////////////
      {
        id: 12,
        label: "Time Span",
        name: "timespan",
        className: "formGroup timeSpan countDiv__column--col6",
        type: "select",
        values: [
          { val: 15, name: "15 Min" },
          { val: 30, name: "30 Min" },
          { val: 45, name: "45 Min" },
          { val: 60, name: "60 Min" },
          { val: 90, name: "90 Min" },
          { val: 120, name: "120 Min" },
          { val: 210, name: "210 Min" },
        ],
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultView: "Time Span",
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.timespan
            : false,
        sx: { m: 1, minWidth: 120 },
      },

      //////////////////////////////// Select Time Zone/////////////////////////////////////
      {
        id: 13,
        label: "Time Zone",
        name: "timezone",
        className: "formGroup timeZone countDiv__column--col6",
        type: "select",
        values: [
          { name: "Alaska Standard Time", val: "-09:00|America/Anchorage" },
          { name: "India Standard Time", val: "+05:30|Asia/Kolkata" },
          { name: "Pacific Standard Time", val: "-08:00|America/Los_Angeles" },
          {
            name: "Mountain Standard Time On DTS (GMT-07:00)",
            val: "-07:00|America/Phoenix",
          },
          { name: "Central Standard Time", val: "-06:00|America/Chicago" },
          { name: "Eastern Standard Time", val: "-05:00|America/New_York" },
          { name: "Hawaii Standard Time", val: "-10:00|Pacific/Honolulu" },
        ],
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultView: "Time Zone",
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.timezone : "",
        sx: { m: 1, minWidth: 120 },
      },

      /////////////////////////////////////// Time Picker Start Time/////////////////////////////
      {
        id: 14,
        label: "Start Time",
        name: "start_time",
        className: "formGroup starTime countDiv__column--col3",
        type: "timePicker",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          lesser:
            "Start time and End time difference must be greater than timespan",
          equal: "Start time and End time can't be same",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.start_time_format_unix_timestamp
            : null,
      },

      /////////////////////////////////////// Time Picker End Time/////////////////////////////
      {
        id: 15,
        label: "End Time",
        name: "end_time",
        className: "formGroup endTime countDiv__column--col3",
        type: "timePicker",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          lesser:
            "Start time and End time difference must be greater than timespan",
          equal: "Start time and End time can't be same",
          lessThanStart: "End time can't be less than Start time",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.end_time_format_unix_timestamp
            : null,
      },

      //////////////////////////////// Select Product/////////////////////////////////////
      // {
      //   id: 16,
      //   label: "Product",
      //   name: "product_id",
      //   className: "formGroup product countDiv__column--col12",
      //   type: "select",
      //   values: [
      //     {
      //       val: "PECE platform and TM-Flow",
      //       name: "PECE platform and TM-Flow",
      //     },
      //     {
      //       val: "BioEnergetics platform with neoGEN Series",
      //       name: "BioEnergetics platform with neoGEN Series",
      //     },
      //   ],
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //   },
      //   defaultView: "Product",
      //   defaultValue: editData && Object.keys(editData).length > 0 ? editData.product_id[0] : "",
      // },
      // {
      //   id: 16,
      //   label: "Event Details",
      //   heading: "Event Details",
      //   name: "description",
      //   className: "formGroup eventDetails sunEditor countDiv__column--col12",
      //   type: "sunEditor",
      //   rows: 4,
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     minLength: "Minimum length 20 required",
      //   },
      //   defaultValue: editData && Object.keys(editData).length > 0 ? editData.description : ''
      // },
      {
        id: 16,
        heading: "Event Details",
        label: "Event Details",
        name: "description",
        type: "sunEditor",
        className: "formGroup eventDetails sunEditor countDiv__column--col12",
        // rules: { required: true },
        rows: 4,
        errorMessage: {
          required: "This Field is Required",
          minLength: "Minimum length should be 15",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.description
            : "",
        placeholder: "Please type here...",
        ////////////////////////////// Mandatory or no options will be available /////////////////////
        editorWidth: 200,
        buttonListOptions: [
          ["undo", "redo", "font", "fontSize", "formatBlock"],
          [
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "removeFormat",
          ],
          [
            "fontColor",
            "hiliteColor",
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "table",
          ],
          [
            "link",
            "image",
            "video",
            "fullScreen",
            "showBlocks",
            "codeView",
            "preview",
            "print",
            "save",
          ],
        ],
      },
      //////////////////////////////// Select Secondary organizer mail/////////////////////////////////////
      // {
      //   id: 17,
      //   label: "Secondary Organizer E-mail",
      //   name: "secondaryorganizeremail",
      //   className: "formGroup secondaryorganizeremail countDiv__column--col12",
      //   type: "select",
      //   values: [
      //     {
      //       val: "PECE platform and TM-Flow",
      //       name: "PECE platform and TM-Flow",
      //     },
      //     {
      //       val: "BioEnergetics platform with neoGEN Series",
      //       name: "BioEnergetics platform with neoGEN Series",
      //     },
      //   ],
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //   },
      //   defaultView: "Product",
      //   defaultValue: editData && Object.keys(editData).length > 0 ? editData.product_id[0] : "",
      // },
    ],
    customButtons: [
      {
        id: "goBack001",
        label: "Cancel",
        className: "submit form_custom_button",
        type: "button",
      },
    ],
    formAdditionalSubmissionData: {
      userid: `${item._id}`,
      username: `${item.firstname} ${item.lastname}`,
      useremail: `${item.email}`,
      id: params.slotID ? params.slotID : null,
    },
  };

  store.subscribe(() => {
    const storeData = store.getState();
    // console.log("storeData", storeData.loginUserKey);
    if (storeData.formSlice.formSubmissionState.createEventForm !== undefined) {
      setFormSubmissionState(
        storeData.formSlice.formSubmissionState.createEventForm
      );
    }
    setItem(storeData.loginUserKey.userInfo);
    // if (storeData.tableSlice.tempEditData.tableId === 'calendarAvailabilityTable' && Object.keys(storeData.tableSlice.tempEditData.editData).length > 0) {
    //   setEditData(storeData.tableSlice.tempEditData.editData);
    // }
  });

  useEffect(() => {
    return () =>
      setTimeout(
        () =>
          dispatch(setOpenSanckBar({ open: false, message: null, type: null })),
        6000
      );
  }, []);

  useEffect(() => {
    if (cancelButtonClicked) navigate("/calendar-management-event-listing");
  }, [cancelButtonClicked]);

  useEffect(() => {
    if (createEventFormData?.start_time && createEventFormData?.end_time) {
      const timeDifference =
        createEventFormData.end_time - createEventFormData.start_time;
      if (
        createEventFormData?.timespan &&
        timeDifference < createEventFormData.timespan * 60 * 1000
      ) {
        dispatch(
          setFormFieldError({
            formId: "createEventForm",
            fieldName: "start_time",
            error: { type: "lesser" },
          })
        );
        dispatch(
          setFormFieldError({
            formId: "createEventForm",
            fieldName: "end_time",
            error: { type: "lesser" },
          })
        );
      }
      if (timeDifference == 0) {
        dispatch(
          setFormFieldError({
            formId: "createEventForm",
            fieldName: "start_time",
            error: { type: "equal" },
          })
        );
        dispatch(
          setFormFieldError({
            formId: "createEventForm",
            fieldName: "end_time",
            error: { type: "equal" },
          })
        );
      }
      if (timeDifference < 0) {
        dispatch(
          setFormFieldError({
            formId: "createEventForm",
            fieldName: "end_time",
            error: { type: "lessThanStart" },
          })
        );
      }
    }
  }, [createEventFormData]);

  useEffect(() => {
    if (createEventFormSubmissionState === 2) {
      dispatch(
        setOpenSanckBar({
          open: true,
          message: params.slotID
            ? "Updated Successfully"
            : "Created Successfully",
          type: "success",
        })
      );
    }
  }, [createEventFormSubmissionState]);

  useEffect(() => {
    if (formSubmissionState === 1) {
      setLoaderFlag(true);
    } else {
      setLoaderFlag(false);
    }
  }, [formSubmissionState]);

  useEffect(() => {
    if (params.slotID) {
      setLoaderFlag(true);
      getdata();
    }
  }, [params]);

  useEffect(() => {
    console.log("formData=======================>", formData);
  }, [formData]);

  useEffect(() => {
    console.log("formCurrData ------------------>", formCurrData);
  }, [formCurrData]);

  const getdata = async () => {
    const reqBody = {
      source: "",
      condition: { limit: 5, skip: 0 },
      sort: { type: "desc", field: "_id" },
      searchcondition: { _id: params.slotID },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(reqBody),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "api/availability-list",
      requestOptions
    );
    const respdata = await response.json();
    setLoaderFlag(false);
    setEditData(respdata.results.res[0]);
    dispatch(
      setTempFormUpdateData({
        formId: "createEventForm",
        fieldName: "start_date",
        value: editData?.start_date,
      })
    );
    dispatch(
      setTempFormUpdateData({
        formId: "createEventForm",
        fieldName: "end_date",
        value: editData?.end_date,
      })
    );
  };

  return (
    <>
      {/* {/ Meta Service Start Here /} */}
      {/* <MetaTags>
        <title>Available Slot</title>
        <meta
          id="meta-description"
          name="description"
          content="One with Dawn for BioEnergetics, Michael for PECE, Integration meeting for PECE and another integration meeting for BioEnergetics."
        />
        <meta id="og-title" property="og:title" content="Available Slot" />
        <meta
          id="og-image"
          property="og:image"
          content="path/to/og_image.jpg"
        />
      </MetaTags> */}
      {/* {/ Meta Service End Here /} */}

      <div className="mainContentWrapper form">
        <div className="ContentWrapper">
          <div className="Formdiv">
            {/* Login Form Start Here */}
            <div className="FormBlock">
              {/* connected email section */}
              {/* <div className="connectedemail">
                <h2>Calender Management available slots</h2>
                <div className="calenderinfo">
                  <p>
                    CONNECTED WITH GOOGLE CALENDAR <br /> LAST UPDATED ON : {googeConnectTime ? moment(Number(googeConnectTime)).format('DD MMMM YYYY') : 'NA'} <br />
                    EMAIL CONNECTED: <span>{connectedGmail}</span>
                  </p>
                </div>
              </div> */}
              <div className="buttonsetToolbar">
                <Button variant="contained">
                  <Link to="/calendar-management-available-slots">
                    Availability Slots
                  </Link>
                </Button>
              </div>
              {loaderFlag == true ? (
                <Box sx={{ width: "100%", mt: "10px" }}>
                  <LinearProgress sx={{ height: "5px" }} />
                </Box>
              ) : (
                ""
              )}
              {/* Form Section Starts */}
              {((params.slotID && editData) || !params.slotID) && (
                <Form formData={formData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateEvent;
